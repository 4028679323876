.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Facebook */
.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354C8C;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}


/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #DD4B39;
}
.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}

/* Account Setting */
.app .table th, .table td {
  border-top: 0px solid #c8ced3;
}

/* Modal */

.modal-footer {
  border-top: 0px;
}

/* */
.ml10 {
  margin-left: 10px;
}

/* DICOM */
#cornerstone-element {
  width: 512px;
  height: 512px;
  margin: 0 auto;
}

/* Chat */
.chat{
  margin-top: auto;
  margin-bottom: auto;
}
.card{
  border-radius: 15px !important;
  background-color: rgba(255,255,255,0.4) !important;
}
.contacts_body{
  padding:  0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body{
  overflow-y: auto;
}
.card-header{
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-footer{
border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.container{
  align-content: center;
}
.search{
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
}
.search:focus{
     box-shadow:none !important;
       outline:0px !important;
}
.type_msg{
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
  height: 60px !important;
  overflow-y: auto;
}
  .type_msg:focus{
     box-shadow:none !important;
       outline:0px !important;
}
.attach_btn{
border-radius: 15px 0 0 15px !important;
background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn{
border-radius: 0 15px 15px 0 !important;
background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.search_btn{
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts{
  list-style: none;
  padding: 0;
}
.contacts li{
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.active{
  background-color: rgba(0,0,0,0.3);
}
.user_img{
  height: 70px;
  width: 70px;
  border:1.5px solid #f5f6fa;

}
.user_img_msg{
  height: 40px;
  width: 40px;
  border:1.5px solid #f5f6fa;

}
.img_cont{
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg{
  height: 40px;
  width: 40px;
}
.online_icon{
position: absolute;
height: 15px;
width:15px;
background-color: #4cd137;
border-radius: 50%;
bottom: 0.2em;
right: 0.4em;
border:1.5px solid white;
}
.offline{
background-color: #c23616 !important;
}
.user_info{
margin-top: auto;
margin-bottom: auto;
margin-left: 15px;
}
.user_info span{
font-size: 20px;
color: white;
}
.user_info p{
font-size: 10px;
color: rgba(255,255,255,0.6);
}
.video_cam{
margin-left: 50px;
margin-top: 5px;
}
.video_cam span{
color: white;
font-size: 20px;
cursor: pointer;
margin-right: 20px;
}
.msg_cotainer{
margin-top: auto;
margin-bottom: auto;
margin-left: 10px;
border-radius: 25px;
background-color: #82ccdd;
padding: 10px;
position: relative;
}
.msg_cotainer_send{
margin-top: auto;
margin-bottom: auto;
margin-right: 10px;
border-radius: 25px;
background-color: #78e08f;
padding: 10px;
position: relative;
}
.msg_time{
position: absolute;
left: 0;
bottom: -15px;
color: rgba(255,255,255,0.5);
font-size: 10px;
}
.msg_time_send{
position: absolute;
right:0;
bottom: -15px;
color: rgba(255,255,255,0.5);
font-size: 10px;
}
.msg_head{
position: relative;
}
#action_menu_btn{
position: absolute;
right: 10px;
top: 10px;
color: white;
cursor: pointer;
font-size: 20px;
}
.action_menu{
z-index: 1;
position: absolute;
padding: 15px 0;
background-color: rgba(0,0,0,0.5);
color: white;
border-radius: 15px;
top: 30px;
right: 15px;
display: none;
}
.action_menu ul{
list-style: none;
padding: 0;
margin: 0;
}
.action_menu ul li{
width: 100%;
padding: 10px 15px;
margin-bottom: 5px;
}
.action_menu ul li i{
padding-right: 10px;

}
.action_menu ul li:hover{
cursor: pointer;
background-color: rgba(0,0,0,0.2);
}
@media(max-width: 576px){
.contacts_card{
margin-bottom: 15px !important;
}
}
.lazyload-placeholder {
  background-color: black;
  width: 180px;
}

.header-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.header-nav li {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: var(--cui-header-color, rgba(44, 56, 74, 0.681));
}
/* CANVAS */
.zindex1 {
  z-index: 1;
}
.zindex2 {
  z-index: 2;
}
.zindex3 {
  z-index: 3;
}
.zindex4 {
  z-index: 4;
}
.book {
  position: relative;
  display: -webkit-box;
  height: 300px;
}
#pages {
  position: absolute;
  left: 0px;
  top: 5px;
}
#pages section {
	display: block;
	position: absolute;
	overflow: hidden;
}
#left-pages {
  position: absolute;
  left: 250px;
	top: 5px;
}
#left-pages section {
	display: block;
	position: absolute;
	overflow: hidden;
}

.book-preview {
  margin: 40px auto;
}

.mt30 {
  margin-top: 30px;
}

.unity-loading-bar {
  position: absolute;
  top: 10px;
  width: 100%;
}

.btn-modal-close {
  right: 0px !important;
}

.customize-radio-btn input[type='radio']:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: -4px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #DABB87;
}

.customize-radio-btn input[type='radio']:checked:after {
  width: 14px;
  height: 14px;
  border-radius: 15px;
  top: -5px;
  left: -0px;
  position: relative;
  background-color: #3B385E;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 4px solid white;
}

.customize-radio-btn .form-check-input:checked {
  background-color: #DABB87;
  border-color: #DABB87;
}

.radio-btn-label {
  font-size: 14px;
}

.customize-radio-btn .form-check-input[type=radio] {
  border-radius: 50%;
  top: 2px;
}

.fixed-height-screen-1{
  height: 1037px;
}
.fixed-height-screen-2{
  height: 1266px;
}
.fixed-height-screen-3{
  height: 759px;
}
.fixed-height-screen-4{
  height: 838px;
}
.fixed-height-screen-5{
  height: 570px;
}

.fixed-height-error-screen-1 {
	height: 1121px;
}
.fixed-height-error-screen-2 {
	height: 1329px;
}
.fixed-height-error-screen-3 {
	height: 800px;
}
.fixed-height-error-screen-4 {
	height: 880px;
}

@media only screen and (max-width: 414px) {
  .fixed-height-screen-1{
    height: 940px;
  }
  .fixed-height-screen-2{
    height: 1166px;
  }
  .fixed-height-screen-3{
    height: 704px;
  }
  .fixed-height-screen-4{
    height: 960px;
  }
  .fixed-height-screen-5{
    height: 920px;
  }
  .fixed-height-error-screen-1 {
    height: 1121px;
  }
  .fixed-height-error-screen-2 {
    height: 1329px;
  }
  .fixed-height-error-screen-3 {
    height: 800px;
  }
  .fixed-height-error-screen-4 {
    height: 1060px;
  }
}

.resize-text div {
  font-size: 20px;
}

.resize-text p {
  font-size: 15px;
  padding-left: 2.0rem;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
}

.resize-text__md p {
  font-size: 16px;
  padding-left: 2.0rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 767px){

  .u-padding-y--50__fixed {
    padding-top: 2.125rem !important;
  }
}


@media only screen and (max-width: 576px){
  .text-align-left {
    text-align: left;
  }

  .resize-text div {
    font-size: 16px;
  }
  
  .resize-text p {
    font-size: 10px;
    padding-left: 1.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
  }

  .resize-text__md p {
    font-size: 12px;
    padding-left: 1.0rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
  }

}

@media only screen and (max-width: 392px){
  .resize-text div {
    font-size: 16px;
  }
  
  .resize-text p {
    font-size: 10px;
    padding-left: 0.75rem;
    
  }

  .resize-text__md p {
    font-size: 12px;
    padding-left: 1.0rem;
  }
}


@media only screen and (max-width: 366px){
  .resize-text div {
    font-size: 14px;
  }
  
  .resize-text p {
    font-size: 8px;
    padding-left: 1.0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .resize-text__md p {
    font-size: 10px;
    padding-left: 1.0rem;
    padding-bottom: 0rem;
  }
}

@media only screen and (max-width: 331px){
  .resize-text p {
    padding-left: 0rem;
  }

  .resize-text__md p {
    padding-left: 0rem;
  }
}

